<template>
    <div>
        <div v-if="editMode=='read'">
            <div v-if="isRanked"># {{index+1}}</div>
            <div v-safe-html="getItemContent" />
            <div style="display:none"> <!-- We load this component to load the css in it -->
                <RichTextViewer :content="item.content" />
            </div>
            <!-- <v-text-field v-model="editRanking" label="Edit Ranking" /> -->
        </div>
        <div v-if="editMode=='write'">
            <!-- TODO: Add Ranking into modal -->
            <RichEditorModal
            :handleSuccess=saveModalContent
            :handleCancel=cancelEdit
            :initialContent=item.content
            />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            editRanking: this.index+1,
            editContent: this.item.content,
        };
    },
    components: {
        RichTextViewer: () => import("/src/components/RichTextViewer.vue"),
        RichEditorModal: () => import("/src/components/RichEditorModal.vue"),
    },
    computed: {
        getItemContent() {
            // return this.item.content;
            // The Quill css rules won't apply unless the html is in a "ql-editor" container.
            return `<div class="ql-editor">${this.item.content}</div>`;
        },
    },
    methods: {
        saveModalContent(newContent) {
            // TODO: Get rank data too.
            // console.log("SortableItem::saveModalContent", newContent);
            this.editContent = newContent;
            this.saveListItem();
        },
    },
    props: ['cancelEdit','editMode','isRanked','item','index','saveListItem'],
};
</script>
<style scoped>
</style>